<template>
  <div>
    <el-breadcrumb separator="/" class="dashboard-breadcrumb">
      <el-breadcrumb-item>全部分类</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="loading-pre-container" v-loading="categoryLoading">
      <div class="dashboard-item" v-for="item in categories" :key="item.uuid" @click="toDashboardList(item)">
        <div class="dashboard-item-container">
          <div class="image-container container">
            <img :src="folderSVG" alt="" />
          </div>

          <div class="dashboard-item-handle" @click.stop>
            <el-dropdown trigger="click" size="small">
              <div class="icon">
                <i class="el-icon-more-outline"></i>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="edit(item)">修改</el-dropdown-item>
                <el-dropdown-item @click.native="deleteItem(item)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <p class="dashboard-item-text">{{ item.name }}</p>
      </div>

      <a-empty v-if="categories.length < 1" />
    </div>

    <el-dialog title="编辑分类" :visible.sync="categoryDialogVisible" width="400px">
      <el-form :model="categoryForm" label-position="top" size="small" ref="categoryForm">
        <el-form-item
          label="分类名称"
          prop="name"
          :rules="[{ required: true, message: '请输入分类名称', trigger: 'blur' }]"
        >
          <el-input v-model="categoryForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="categoryFormSubmit" size="small">{{ $t("handle.submit") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCategories, updateCategory, deleteCategory } from "api/dashboard.js";
import folderSVG from "@/assets/folder.svg";
import { cloneDeep } from "lodash";
export default {
  data() {
    return {
      folderSVG,
      categories: [],
      categoryDialogVisible: false,
      categoryForm: {
        name: ""
      },
      categoryLoading: true
    };
  },

  methods: {
    getCategories() {
      this.categoryLoading = true;
      getCategories().then(response => {
        this.categoryLoading = false;
        if (response.code === 0) {
          this.categories = response.data;
        }
      });
    },

    toDashboardList(item) {
      this.$router.push({
        path: `/dashboard-custom/category/${item.uuid}/dashboard`,
        query: { name: item.name }
      });
    },

    edit(item) {
      this.categoryDialogVisible = true;
      this.categoryForm = cloneDeep(item);
    },

    categoryFormSubmit() {
      this.$refs["categoryForm"].validate(valid => {
        if (valid) {
          updateCategory(this.categoryForm).then(response => {
            if (response.code === 0) {
              this.categoryDialogVisible = false;
              this.getCategories();
            }
          });
        }
      });
    },

    deleteItem(item) {
      this.$confirm(this.$t("delete", [item.name]), this.$t("prompt"))
        .then(() => {
          deleteCategory(item.uuid).then(response => {
            if (response.code === 0) {
              this.getCategories();
            }
          });
        })
        .catch(() => {});
    }
  },

  mounted() {
    this.getCategories();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/dashboard.scss";
</style>
